<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :search.sync="search"
                 v-model="form">
        <template slot="menu"
                  slot-scope="{row,index,size}">
          <el-button icon="el-icon-lock"
                     @click="handleResetPassword(row)"
                     type="text"
                     :size="size">重置密码</el-button>
          <el-button icon="el-icon-setting"
                     type="text"
                     :size="size"
                     @click="handlePermission(row,index)">设置角色</el-button>
        </template>
      </avue-crud>
    </el-card>
    <el-dialog class="avue-dialog"
               append-to-body
               destroy-on-close
               title="设置角色"
               :before-close="handleClose"
               :visible.sync="box"
               width="40%">
      <el-tree :data="treeList"
               show-checkbox
               :default-checked-keys="treeCheck"
               node-key="id"
               @check="handleCheckChange">
      </el-tree>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="box = false">取 消</el-button>
        <el-button type="primary"
                   @click="setPermission">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重置密码 -->
    <el-dialog class="avue-dialog"
               title="重置密码"
               :visible.sync="passBox"
               width="40%">
      <el-form :model="passForm"
               :rules="passRules"
               ref="password"
               label-width="110px">
        <el-form-item label="输入密码"
                      prop="resetPassword1">
          <el-input v-model="passForm.resetPassword1"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码"
                      prop="resetPassword2">
          <el-input v-model="passForm.resetPassword2"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="setResetPassword">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { bind, list } from '@/api/sys/role'
import { detail, update } from '@/api/sys/user'
export default window.$crudCommon({
  data () {
    const validatePass = (rule, value, callback) => {
      if (this.passForm.resetPassword1 != this.passForm.resetPassword2) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    return {
      box: false,
      passBox: false,
      passForm: {},
      passRules: {
        resetPassword1: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        resetPassword2: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePass },
        ],
      },
      treeForm: {},
      treeCheck: [],
      treeList: [],
      treeData: []
    }
  },
  created () {
    this.getPermission()
  },
  methods: {
    handleResetPassword (row) {
      this.passForm = row;
      this.passBox = true
    },
    setResetPassword () {
      this.$refs.password.validate((valid) => {
        if (valid) {
          this.passForm.password = this.passForm.resetPassword1;
          update(this.passForm.id, this.passForm).then((res) => {
            this.passBox = false;
            this.passForm = {}
          });
        }
      });
    },
    handleClose (done) {
      this.treeData = []
      this.treeCheck = []
      done()
    },
    getPermission () {
      list({ size: 1000 }).then(res => {
        let list = res.data.records || []
        list = list.map(ele => {
          return Object.assign(ele, {
            label: `${ele.name}[${ele.code}]`
          })
        })
        this.treeList = list
      })
    },
    handleCheckChange (checkedNodes, checkedKeys) {
      this.treeData = checkedKeys.checkedKeys
    },
    setPermission () {
      bind(this.treeForm.id, this.treeData).then(res => {
        this.$message.success('角色设置成功')
        this.treeData = []
        this.treeCheck = []
        this.box = false
      })
    },
    handlePermission (row) {
      detail(row.id).then(res => {
        let data = (res.data.roles || []).map(ele => ele.id)
        this.treeCheck = data;
        this.box = true
        this.treeForm = row;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/sys/user',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>